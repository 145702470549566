@import "~styles/variables";

@import "styles/_mixins.scss";

/**
*   MAIN STYLES
*/
.main {
  height: 100%;
  overflow: hidden;

  > header {
    height: 50px;
    color: $textColor;
    background: white;
    box-shadow: 0px 0px 5px rgba($color: $secondaryColor, $alpha: 1);
    display: flex;
    align-items: center;
    padding: 0px 5em;
    justify-content: space-between;
    transition: all 0.2s ease-in-out;

    > h3 {
      color: rgba($color: $primaryColor, $alpha: 1);
    }
    .right-side-content {
      display: flex;
    }

    @include xs {
      padding: 0px 1em;
    }
    @include sm {
      padding: 0px 2em;
    }
    @include md {
      padding: 0px 3em;
    }
    @include xl {
      padding: 0px 5em;
    }
  }
  .content {
    position: relative;
    background: white;
    // background: rgba(100, 100, 100, 0.05);
  }
}
