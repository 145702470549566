// Different Options for theme base colors

// $primaryColor: yellow;
// $primaryColor: green;
// $primaryColor: blue;
// $primaryColor: red;
// $primaryColor: magenta;

$primaryColor: #4caf50;

$secondaryColor: rgba(100, 100, 100, 0.5);

$searchInputColor: rgba(
  $color: #000000,
  $alpha: 0.65
);
$textColor: rgba(
  $color: #000000,
  $alpha: 0.5
);
